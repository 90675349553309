.discarded-cards-drawer-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: rgb(15, 15, 19);
    max-height: 50vh;
}

.discarded-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.close-button-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.cards-left-container {
    font-family: 'Poppins';
    text-transform: uppercase;
    color: #fbae3d;
    font-weight: bold;
    text-align: center;
}