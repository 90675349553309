.game-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* sets the element as a positioning context for its child elements */
    width: 100vw;
    /* sets the element to take up the full width of the viewport */
    height: 100vh;
    /* sets the element to take up the full height of the viewport */
}

.deck-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* sets the element to take up 80% of the available width */
}

.options {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.deck {
    width: 300px;
    height: 450px;
}

.card-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: #fbae3d;
    font-family: 'Poppins';
    width: min(700px, 100vw);
}

.card-info-title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
}

.card-info-explanation {
    padding: 10px;
    font-size: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.confetti-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.kings-warning-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    text-align: center;
    background-color: rgb(179, 3, 3);
    color: rgb(247, 216, 213);
    border-radius: 20px;
    border-style: dotted;
    border-width: 6px;
    border-color: red;
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 30px;
    animation: pulse 1.5s infinite;
    z-index: 100;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-weight: bold;
    pointer-events: none;
}

.kings-cup {
    background: linear-gradient(45deg,
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(63, 218, 216, 1) 40%,
            rgba(47, 201, 226, 1) 50%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%) !important;
    animation: kings-pulse 1.5s infinite !important;
    border-color: rgb(0, 255, 162) !important;
    color: rgb(160, 147, 116);
}

.discarded-cards-drawer .MuiDrawer-paper {
    background-color: rgb(15, 15, 19);
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgb(255, 0, 0);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.404);
    }
}

@keyframes kings-pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgb(255, 0, 0);
    }

    50% {
        transform: scale(1.1);
        box-shadow: 0 0 0 20px rgba(0, 8, 255, 0.469);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 255, 38, 0.48);
    }
}

@media screen and (max-width: 700px) {
    .deck {
        width: 200px;
        height: 300px;
    }

    .kings-warning-container {
        border-radius: 20px;
        border-style: dotted;
        border-width: 6px;
        border-color: red;
        margin: 0 10px;
        padding: 10px 20px;
        font-size: 20px;
    }
}

@media screen and (max-width: 740px) {
    .card-info-title {
        font-size: 30px;
    }

    .card-info-explanation {
        font-size: 1em;
    }
}

@media screen and (max-width: 350px) {
    .card-info-explanation {
        font-size: 0.8em;
    }
}