.flip-card {
    perspective: 1000px;
    z-index: 1;
    border-radius: 30px;
    width: 300px;
    height: 450px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    border-radius: 30px;
    background-color: #bbb;
    width: inherit;
    height: inherit;
    z-index: -1;
    position: relative;
}

.card-back {
    transform: scale(-1, 1);
    border-radius: 30px;
}

.card-graphic,
.card-graphic img {
    width: inherit;
    height: inherit;
    z-index: -1;
    position: relative;
}

@media screen and (max-width: 700px) {
    .flip-card {
        width: 200px;
        height: 300px;
    }
}