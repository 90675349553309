.card {
    width: 90px;
    height: 135px;
    border-radius: 20px;
    padding: 10px
}

.card img {
    width: inherit;
    height: inherit;
}

@media screen and (max-width: 900px) {
    .card {
        width: 75px;
        height: 113px;
    }
}

@media screen and (max-width: 600px) {
    .card {
        width: 50px;
        height: 75px;
    }
}